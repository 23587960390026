import React, { useContext } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { AuthContext, IAuthContext } from "react-oauth2-code-pkce";
import { useCurrentSsoUser } from "./hooks/auth";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

import { Layout, ListDetailLayout } from "./pages/Layout";
import { ScrollToTop } from "./components/ScrollToTop";
import { useTracking } from "./services/tracking";

import "./index.css";
import { AccountList } from "./components/AccountList";
import { AccountForm } from "./components/AccountForm";
import { AccountSummary } from "./components/AccountSummary";
import { UserList } from "./components/UserList";
import { ProfileForm, UserForm } from "./components/UserForm";
import { CameraList } from "./components/CameraList";
import { CameraForm } from "./components/CameraForm";
import { VehicleListAdminPanel } from "./components/VehicleList";
import { VehicleForm } from "./components/VehicleForm";
import { AlertSettingsForm } from "./components/AlertSettings";
import { ssoAdminRole } from "./constants";
import { EventsPage } from "./pages/Events";
import { VehiclesPage } from "./pages/Vehicles";
import { User } from "./interfaces";
import { UserContext } from "./contexts/UserContext";
import { VehiclePage } from "./pages/Vehicle";
import { MediaPage } from "./pages/Media";
import { CalendarPage } from "./pages/Calendar";
import {
  ImpersonatedUser,
  ImpersonationContext,
} from "./contexts/ImpersonationContext";
import { LivestreamPage } from "./pages/Livestream";
import { ReportList } from "./components/ReportList/containers";
import { SignInPage } from "./pages/SignIn";
import RamLiveDemo from "./components/LiveMarketingDemo/RamLiveDemo";

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      minHeight: "100vh",
    },
  })
);

const HomePage: React.FC = () => {
  const auth: IAuthContext = useContext(AuthContext);
  const { pathname } = useLocation();

  if (auth.loginInProgress) {
    return <></>;
  }

  if (!auth.token) {
    if (pathname !== "/") window.location.href = "/";
    return <SignInPage />;
  }

  if (auth.error) {
    return <SignInPage />;
  }

  return <DashboardPage />;
};

/**
 * The root component of our app.
 *
 * We define all of the routes for the various screens here
 */
const App: React.FC = () => {
  const classes = useStyles();

  const [ctxUser, setCtxUser] = React.useState<User | undefined>(undefined);
  const [iUser, setIUser] = React.useState<ImpersonatedUser | undefined>(
    undefined
  );

  React.useEffect(() => {
    if (iUser) {
      localStorage.setItem("impersonation-accountid", iUser.id);
    } else {
      localStorage.setItem("impersonation-accountid", "");
    }
  }, [iUser]);
  useTracking();

  return (
    <>
      <CssBaseline />
      <div className={classes.root}>
        <ImpersonationContext.Provider value={{ iUser, setIUser }}>
          <UserContext.Provider value={{ ctxUser, setCtxUser }}>
            <HomePage />
          </UserContext.Provider>
        </ImpersonationContext.Provider>
      </div>
    </>
  );
};

const DashboardPage: React.FC = () => {
  const { iUser } = useContext(ImpersonationContext);
  const { user, ssoUser } = useCurrentSsoUser();

  if (!user) {
    if (ssoUser) {
      return (
        <RamLiveDemo
          salesforceId={ssoUser.salesforceId}
          salesforceContactId={ssoUser.salesforceContactId}
        />
      );
    }
    return <></>;
  }

  return (
    <ScrollToTop>
      <Switch>
        {/**
         * Vehicles landing screen
         */}
        <Route exact path="/vehicles">
          <VehiclesPage />
        </Route>

        <Route exact path="/vehicles/:vehicleId/stream">
          <LivestreamPage />
        </Route>
        <Route path="/vehicles/:vehicleId/:section?/:journeyId?">
          <VehiclePage />
        </Route>
        {/**
         * Main events screen
         */}
        <Route path="/events/:vehicleId?/:eventId?">
          <EventsPage />
        </Route>

        <Route path="/video/:vehicleId?/:eventId?">
          <MediaPage />
        </Route>

        {/**
         * Calendar screen
         */}
        <Route path="/calendar">
          <CalendarPage />
        </Route>

        {/**
         * User profile screen
         */}
        <Route path="/profile">
          <Layout>
            <ProfileForm />
          </Layout>
        </Route>

        {/**
         * Settings screen
         * For now this just covers account alerts
         */}
        <Route path="/settings">
          <Layout>
            <AlertSettingsForm />
          </Layout>
        </Route>

        {/**
         * Admin screens - accounts
         */}
        <Route path="/accounts" exact>
          <Layout>
            <AccountList />
          </Layout>
        </Route>
        <Route path="/accounts/create">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountList />
              <AccountForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountList />
              <AccountSummary />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/edit" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountList />
              <AccountForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        {/**
         * Admin screens - users
         */}
        <Route path="/accounts/summary/:accountId/users" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <UserList />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/users/create">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <UserForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/users/edit/:userId">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <UserForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        {/**
         * Admin screens - cameras
         */}
        <Route path="/accounts/summary/:accountId/cameras" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <CameraList />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/cameras/create">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <CameraForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/cameras/edit/:cameraId">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <CameraForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        {/**
         * Admin screens - vehicles
         */}
        <Route path="/accounts/summary/:accountId/vehicles" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <VehicleListAdminPanel />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/vehicles/create" exact>
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <VehicleForm />
            </ListDetailLayout>
          </Layout>
        </Route>
        <Route path="/accounts/summary/:accountId/vehicles/edit/:vehicleId">
          <Layout menuCollapsed>
            <ListDetailLayout>
              <AccountSummary />
              <VehicleForm />
            </ListDetailLayout>
          </Layout>
        </Route>

        <Route path="/reports" exact>
          <Layout>
            <ReportList />
          </Layout>
        </Route>

        {/**
         * Fallback route - events for customers, accounts for admins
         */}
        <Route path="/">
          {user.roles?.includes(ssoAdminRole) && !iUser ? (
            <Layout>
              <AccountList />
            </Layout>
          ) : (
            <VehiclesPage />
          )}
        </Route>
      </Switch>
    </ScrollToTop>
  );
};

const AppWithRouter: React.FC = () => (
  <Router>
    <App />
  </Router>
);

export default AppWithRouter;
